import * as ReactRouter from 'react-router-dom'
import React from 'react'

import * as CombinedSelectors from './combined-selectors'
import { useSafeUpdateQuery } from './use-safe-update-query'

/**
 * A hook to enforce opening of the phone verification if it is required
 * for the user.
 */
export function usePhoneVerificationEnforcing() {

  const location = ReactRouter.useLocation()
  const history = ReactRouter.useHistory()
  const isPhoneVerificationRequired = CombinedSelectors.useIsPhoneVerificationRequired()

  const safeQuery = useSafeUpdateQuery({
    'phone-verification': 'me',
    login: null,
  })

  React.useEffect(() => {
    if (isPhoneVerificationRequired) {
      const currentSearch = new URLSearchParams(location.search).toString()
      const newSearch = new URLSearchParams(safeQuery).toString()

      if (currentSearch !== newSearch) {
        history.push({
          search: safeQuery,
        })
      }
    }
  }, [isPhoneVerificationRequired, safeQuery, location.search, location.pathname, history])
}
